import React, { FC, useEffect, useState } from 'react'
import s from './Videos.module.scss'
import TopBanner from '../../components/TopBanner/TopBanner'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { NavLink } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { scrollToTop } from '../../utils'
import { fetchByVideos } from '../../store/slices/videosSlice'
import CardVideo from '../../components/CardVideo/CardVideo'
import ModalDetailVideo from './ModalDetailVideo/ModalDetailVideo'
import { LayoutGroup } from 'framer-motion'
import Loader from '../../components/Loader/Loader'
import { Helmet } from 'react-helmet-async'
import { IVideos } from '../../store/types'

const Videos: FC = () => {
    const { videos, loading } = useAppSelector(state => state.videos)
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [data, setData] = useState<IVideos | null>(null);
    const dispatch = useAppDispatch()


    useEffect(() => {
        scrollToTop()
        dispatch(fetchByVideos())
    }, [dispatch])


    return (
        <>
            <Helmet>
                <meta property="og:title" content="Видео | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs" />
                <meta property="og:description" content="Посмотрите видео от Phoenix Programs о наших передовых решениях в автоматизации и различных отраслях. Узнайте больше о наших проектах и технологиях." />
                <meta name="twitter:title" content="Видео | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs" />
                <meta name="twitter:description" content="Посмотрите видео от Phoenix Programs о наших передовых решениях в автоматизации и различных отраслях. Узнайте больше о наших проектах и технологиях." />
                <link rel="canonical" href="https://phoenix-programs.com/videos" />
                <title>Видео | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs</title>
            </Helmet>
            {loading && <Loader />}
            <LayoutGroup id={"video"}>
                <TopBanner title='Видео' />
                <Breadcrumbs>
                    <NavLink className={({ isActive }) => isActive ? s.active : ''} to={'/'} >Главная</NavLink>
                    /
                    <NavLink className={({ isActive }) => isActive ? s.active : ''} to={'/videos'} >Видео</NavLink>
                </Breadcrumbs>
                <section className={s.section}>
                    <div className={`${s.wrapper} container`}>
                        {
                            videos?.length !== 0 ?
                                <>
                                    {
                                        videos?.map((el) => (
                                            <CardVideo key={el.id} data={el} setSelectedId={setSelectedId} setData={setData} />
                                        ))
                                    }
                                </>
                                :
                                <p className={s.warning}>Материал отсутствует</p>
                        }
                    </div>
                </section>
                <ModalDetailVideo setSelectedId={setSelectedId} selectedId={selectedId} data={data} />
            </LayoutGroup>

        </>
    )
}

export default Videos