import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storesApi } from "../../api";
import { IVideos } from "../types";



type VideosState = {
    loading: boolean
    error: null | string
    videos: null | IVideos[]
    detail: null | IVideos
}

const initialState: VideosState = {
    error: null,
    loading: false,
    videos: null,
    detail: null
}


export const fetchByVideos = createAsyncThunk(
    'videos/fetchByVideos',
    async (_, { rejectWithValue }) => {
        const res = await storesApi.getVideos()
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data.data
    })


export const fetchByDetailVideo = createAsyncThunk<IVideos, number, { rejectValue: string }>(
    'videos/fetchByDetailVideo',
    async (id, { rejectWithValue }) => {
        const res = await storesApi.getDetailVideo(id)
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data
    }
)



const infoSlice = createSlice({
    name: 'videos',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchByVideos.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        addCase(fetchByVideos.fulfilled, (state, action) => {
            state.videos = action.payload;
            state.loading = false;
        });
        addCase(fetchByVideos.rejected, (state) => {
            state.loading = false;
            state.error = 'Ошибка сервера!';
        });
        // ==============================
        addCase(fetchByDetailVideo.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        addCase(fetchByDetailVideo.fulfilled, (state, action) => {
            state.detail = action.payload;
            state.loading = false;
        });
        addCase(fetchByDetailVideo.rejected, (state) => {
            state.loading = false;
            state.error = 'Ошибка сервера!';
        });
    },
})



export default infoSlice.reducer





