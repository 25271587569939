import React, { FC, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from '../../pages/Home/Home'
import Services from '../../pages/Services/Services'
import { useAppDispatch } from '../../store/hooks'
import { fetchByCompanyInfo } from '../../store/slices/infoSlice'
import DetailService from '../../pages/DetailService/DetailService'
import Projects from '../../pages/Projects/Projects'
import Articles from '../../pages/Articles/Articles'
import Videos from '../../pages/Videos/Videos'
import AboutUs from '../../pages/AboutUs/AboutUs'
import DetailProject from '../../pages/DetailProject/DetailProject'
import DetailArticle from '../../pages/DetailArticle/DetailArticle'

const Main: FC = () => {
    const location = useLocation();
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchByCompanyInfo())
    }, [dispatch])

    return (
        <main>
            <Routes key={location.pathname} location={location}>
                <Route index element={<Home />} />
                <Route path='/services/:id/:name' element={<Services />} />
                <Route path='/detail-service/:id/:name' element={<DetailService />} />
                <Route path='/detail-project/:id/:name' element={<DetailProject />} />
                <Route path='/projects' element={<Projects />} />
                <Route path='/about-us' element={<AboutUs />} />
                <Route path='/articles' element={<Articles />} />
                <Route path='/detail-article/:id/:name' element={<DetailArticle />} />
                <Route path='/videos' element={<Videos />} />
            </Routes>

        </main>
    )
}

export default Main