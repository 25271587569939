import React, { FC, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper';
import { FreeMode, Mousewheel, Navigation, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import s from './SwiperProject.module.scss';

import { BACKEND_URL } from '../../api';

interface Iimages {
    id: number;
    type: string;
    src: string;
}

interface DataProps {
    image: string;
    data: Iimages[];
}

const SwiperProject: FC<DataProps> = ({ data, image }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const customStyle: React.CSSProperties & { [key: string]: string } = {
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
    };

    useEffect(() => {
        const videoElements = document.querySelectorAll(`.${s.generalSlide} video`) as NodeListOf<HTMLVideoElement>;
        videoElements.forEach((video) => {
            if (video.closest('.swiper-slide-active')) {
                video.muted = false;
                video.play().catch((err) => console.error('Error playing video:', err));
            } else {
                video.pause();
                video.currentTime = 0;
            }
        });
    }, [activeIndex]);


    const handleVideoClick = (event: React.MouseEvent<HTMLVideoElement>) => {
        const video = event.currentTarget;
        if (video.paused) {
            video.muted = false;
            video.play().catch((err) => console.error('Error playing video:', err));
        } else {
            video.pause();
        }
    };

    return (
        <div className={s.wrapper}>
            <Swiper
                style={customStyle}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className={s.generalSwiper}
                onSlideChange={(swiper: SwiperCore) => setActiveIndex(swiper.realIndex)}
            >
                <SwiperSlide className={s.generalSlide}>
                    <img src={BACKEND_URL + image} alt="Main product" />
                </SwiperSlide>
                {data.map((item, index) => (
                    <SwiperSlide className={s.generalSlide} key={index}>
                        {item.type === 'image' ? (
                            <img src={BACKEND_URL + item.src} alt={item.id.toString()} />
                        ) : (
                            <video
                                muted
                                onClick={handleVideoClick}
                                controls
                            >
                                <source src={BACKEND_URL + item.src} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                direction="vertical"
                spaceBetween={15}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs, Mousewheel]}
                className={s.navigationSwiper}
                grabCursor
                breakpoints={{
                    300: {
                        direction: 'horizontal',
                        slidesPerView: 3.4,
                        slidesPerGroup: 3.4,
                    },
                    992: {
                        direction: 'vertical',
                        slidesPerView: 4.5,
                        slidesPerGroup: 4.5,
                    },
                }}
                mousewheel
            >
                <SwiperSlide className={s.slide}>
                    <img src={BACKEND_URL + image} alt="Main product" />
                </SwiperSlide>
                {data.map((item, index) => (
                    <SwiperSlide className={s.slide} key={index}>
                        {item.type === 'image' ? (
                            <img src={BACKEND_URL + item.src} alt={item.id.toString()} />
                        ) : (
                            <video controls={false} muted>
                                <source src={BACKEND_URL + item.src} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default SwiperProject;
