import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storesApi } from "../../api";
import { IArticles } from "../types";



type ArticlesState = {
    loading: boolean
    error: null | string
    articles: null | IArticles[]
    detail: IArticles | null
}

const initialState: ArticlesState = {
    error: null,
    loading: false,
    articles: null,
    detail: null
}


export const fetchByArticles = createAsyncThunk(
    'articles/fetchByArticles',
    async (_, { rejectWithValue }) => {
        const res = await storesApi.getArticles()
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data.data
    })


export const fetchByDetailArticle = createAsyncThunk<IArticles, number, { rejectValue: string }>(
    'articles/fetchByDetailArticle',
    async (id, { rejectWithValue }) => {
        const res = await storesApi.getDetailArticle(id)

        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data
    })




const infoSlice = createSlice({
    name: 'articles',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchByArticles.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        addCase(fetchByArticles.fulfilled, (state, action) => {
            state.articles = action.payload;
            state.loading = false;
        });
        addCase(fetchByArticles.rejected, (state) => {
            state.loading = false;
            state.error = 'Ошибка сервера!';
        });

        // ============================

        addCase(fetchByDetailArticle.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        addCase(fetchByDetailArticle.fulfilled, (state, action) => {
            state.detail = action.payload;
            state.loading = false;
        });
        addCase(fetchByDetailArticle.rejected, (state) => {
            state.loading = false;
            state.error = 'Ошибка сервера!';
        });


    },
})


export default infoSlice.reducer





