import React, { Dispatch, FC, SetStateAction } from 'react';
import s from './CardVideo.module.scss';
import { IVideos } from '../../store/types';
import { truncateText } from '../../utils';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';

interface dataProps {
    setSelectedId: (id: string | null) => void;
    data: IVideos | null;
    setData: Dispatch<SetStateAction<IVideos | null>>;
}

const CardVideo: FC<dataProps> = ({ data, setSelectedId, setData }) => {
    const idString = data?.id?.toString() || undefined;



    return (
        <motion.div
            layoutId={idString}
            onClick={() => {
                if (data) {
                    setData(data);
                    setSelectedId(idString ?? null);
                }
            }}
        >
            <div className={s.card}>
                <div className={s.box_img}>{parse(data?.link ?? "")}</div>
                <div className={s.box_content}>
                    <h2 className='title'><span />{truncateText(data?.title, 30)}</h2>
                    <div className={s.date}>
                        <p>Дата выпуска:</p>
                        <span>{data?.publishedAt}</span>
                    </div>
                    <div className={s.description}>
                        <span className={s.title}>Описание</span>
                        <p>{truncateText(data?.description, 50)}
                            <span className={s.more}> Еще</span>
                        </p>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default CardVideo;
