import React, { FC } from 'react'
import s from './Hero.module.scss'
import { useAppSelector } from '../../../store/hooks'
import { ReactComponent as LineTitle } from './../../../assets/icons/line_for_text1.svg'
import { ReactComponent as HeroText1 } from './../../../assets/icons/line_for_text2.svg'
import { ReactComponent as HeroText3 } from './../../../assets/icons/line_for_text3.svg'

const Hero: FC = () => {
    const { info } = useAppSelector(state => state.info)
    return (
        <section className={s.section}>
            <div className={s.bg} />
            <div className={`${s.wrapper} container`}>
                <div className={s.content}>
                    <h1><span>PHOENIX</span> PROGRAMS <LineTitle /></h1>
                    <h2 className={s.hero_title}>{info?.hero_text1}<HeroText1 /></h2>
                    <h2 className={s.hero_text}>{info?.hero_text2}<HeroText3 /></h2>
                    {/* <h1><span>PHOENIX</span> PROGRAMS </h1>
                    <h2 className={s.hero_title}>{info?.hero_text1}</h2>
                    <h2 className={s.hero_text}>{info?.hero_text2}</h2> */}
                </div>
            </div>
        </section>
    )
}

export default Hero