import { fetchByArticles } from './articlesSlice';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storesApi } from "../../api";
import { IAboutUS, IInfoAndHome } from "../types";



type InfoState = {
    loading: boolean
    error: null | string
    info: null | IInfoAndHome
    aboutUs: null | IAboutUS
}

const initialState: InfoState = {
    error: null,
    loading: false,
    info: null,
    aboutUs: null
}


export const fetchByCompanyInfo = createAsyncThunk(
    'info/fetchByCompanyInfo',
    async (_, { rejectWithValue }) => {
        const res = await storesApi.getMainAndInfoCompany()
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data
    })


export const fetchByAboutUs = createAsyncThunk(
    'info/fetchByAboutUs',
    async (_, { rejectWithValue }) => {
        const res = await storesApi.getAboutUs()
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data
    }
)




const infoSlice = createSlice({
    name: 'info',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchByCompanyInfo.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        addCase(fetchByCompanyInfo.fulfilled, (state, action) => {
            state.info = action.payload;
            state.loading = false;
        });
        addCase(fetchByCompanyInfo.rejected, (state) => {
            state.loading = false;
            state.error = 'Ошибка сервера!';
        });
        // =================================

        addCase(fetchByAboutUs.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        addCase(fetchByAboutUs.fulfilled, (state, action) => {
            state.aboutUs = action.payload;
            state.loading = false;
        });
        addCase(fetchByAboutUs.rejected, (state) => {
            state.loading = false;
            state.error = 'Ошибка сервера!';
        });
    },
})



export default infoSlice.reducer





