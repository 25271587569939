import React, { FC } from 'react'
import s from './CardProject.module.scss'
import { BACKEND_URL } from '../../api'
import { motion } from 'framer-motion'
import { ReactComponent as ArrowRight } from './../../assets/icons/arrow_right.svg'
import { Link } from 'react-router-dom'

interface dataProps {
    data: {
        id: number;
        preview_description: string;
        preview_img: string;
        title: string;
    };
}

const CardProject: FC<dataProps> = ({ data }) => {

    return (

        <Link to={`/detail-project/${data.id}/${data.title}`} className={s.card}>
            <div className={s.box_img}>
                <img src={BACKEND_URL + data.preview_img} alt={data.title} />
            </div>
            <div className={s.box_content}>
                <h2 className='title'><span />{data.title}</h2>
                <p>{data.preview_description}</p>
            </div>
            <div className={s.btn}>
                <button className='button_card'>Подробнее <ArrowRight /></button>
            </div>
        </Link>

    )
}

export default CardProject
