import React, { FC } from 'react'
import s from './TopBanner.module.scss'

interface dataProps {
    title: string | undefined
}
const TopBanner: FC<dataProps> = ({ title }) => {
    return (
        <section className={s.section}>
            <div className={`${s.shape}`} />
            <span className='lineGradient bottom-line' />
            <div className={`${s.wrapper} container`}>
                <h1 className='title'><span />{title}</h1>
            </div>
        </section>
    )
}

export default TopBanner