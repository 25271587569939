import React, { FC, useEffect, useState } from 'react'
import s from './OurServices.module.scss'
import { useAppSelector } from '../../../store/hooks'
import { BACKEND_URL } from '../../../api'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { animationLifting } from '../../../utils'
const OurServices: FC = () => {
    const { info } = useAppSelector(state => state.info)
    const controls = useAnimation()
    const { ref, inView } = useInView({ threshold: 0.1 })
    const [hasAnimated, setHasAnimated] = useState(false)

    useEffect(() => {
        if (inView && !hasAnimated) {
            controls.start('visible')
            setHasAnimated(true)
        }
    }, [controls, inView, hasAnimated])

    return (
        <section className={s.section}>
            <div className={`${s.container} container`}>
                <div className={s.box_text}>
                    <h2 className='title'><span />ПОМОЖЕМ ВОПЛАТИТЬ ЗАДУМАННОЕ</h2>
                    <p>{info?.our_services_description}</p>
                </div>
                <motion.div
                    className={`${s.wrapper}`}
                    initial="hidden"
                    animate={controls}
                    variants={animationLifting}
                    ref={ref}
                >
                    {info?.our_services_card.map((el) => (
                        <div className={s.card} key={el.id}>
                            <img src={BACKEND_URL + el.icon} alt="icon" />
                            <p>{el.title}</p>
                        </div>
                    ))}
                </motion.div>
            </div>
            <div className={`${s.shape_right}`} />
        </section>
    )
}

export default OurServices