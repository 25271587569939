import React, { FC } from 'react'
import s from './Header.module.scss'
import { Link, NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from './../../assets/icons/logo.svg';
import { ReactComponent as Phone } from './../../assets/icons/phone.svg';
import { ReactComponent as Mail } from './../../assets/icons/mail.svg';
import { ReactComponent as Telegram } from './../../assets/icons/telegram.svg';
import { ReactComponent as Youtube } from './../../assets/icons/youtube.svg';
import { ReactComponent as Whatsapp } from './../../assets/icons/whatsapp.svg';
import Dropdown from '../Dropdown/Dropdown';
import { useAppSelector } from '../../store/hooks';
import BurgerMenu from '../BurgerMenu/BurgerMenu';


const Header: FC = () => {
    const { info } = useAppSelector(state => state.info)

    return (
        <header className={s.header}>
            <BurgerMenu />
            <nav className={`${s.nav} container`}>
                <Link to={'/'} className={s.logo} >
                    <Logo />
                </Link>
                <ul className={s.menu}>
                    <Dropdown title={'Услуги'}>
                        {info?.list_services?.map((el) => (
                            <NavLink key={el.id} className={({ isActive }) => isActive ? s.dropdown_active : ''} to={`/services/${el.id}/${el.name}`}>{el.name}</NavLink>
                        ))}
                    </Dropdown>
                    <li><NavLink className={({ isActive }) => isActive ? s.active : ''} to={'/projects'} >Проекты</NavLink></li>
                    {/* <li><NavLink className={({ isActive }) => isActive ? s.active : ''} to={'/catalog'} >Продукция</NavLink></li> */}
                    <li><NavLink className={({ isActive }) => isActive ? s.active : ''} to={'/about-us'} >О нас</NavLink></li>
                    <li><NavLink className={({ isActive }) => isActive ? s.active : ''} to={'/videos'} >Видео</NavLink></li>
                    <li><NavLink className={({ isActive }) => isActive ? s.active : ''} to={'/articles'} >Статьи</NavLink></li>
                </ul>
                <ul className={s.contacts}>
                    <div>

                        <li><a rel="noreferrer noopener" href={`tel:${info?.contacts?.phone}`}><Phone />{info?.contacts?.phone}</a></li>
                        <li><a rel="noreferrer noopener" href={`mailto:${info?.contacts?.email}`}><Mail />{info?.contacts?.email}</a></li>
                    </div>
                    <div>
                        <li><a target='_blank' rel="noreferrer noopener" href={`https://t.me/${info?.contacts?.telegram}`}><Telegram /></a></li>
                        <li><a target='_blank' rel="noreferrer noopener" href={`https://www.youtube.com/${info?.contacts?.youtube}`}><Youtube /></a></li>
                        <li><a target='_blank' rel="noreferrer noopener" href={`https://wa.me/${info?.contacts?.whatsapp}`}><Whatsapp /></a></li>
                    </div>
                </ul>
            </nav>
        </header>
    )
}

export default Header