import React, { FC, useEffect, useState } from 'react'
import s from './Articles.module.scss'
import TopBanner from '../../components/TopBanner/TopBanner'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { NavLink } from 'react-router-dom'
import CardArticle from '../../components/CardArticle/CardArticle'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { scrollToTop } from '../../utils'
import { fetchByArticles } from '../../store/slices/articlesSlice'
import Loader from '../../components/Loader/Loader'
import { IArticles } from '../../store/types'
import { Helmet } from 'react-helmet-async'

const Articles: FC = () => {
    const { articles, loading } = useAppSelector(state => state.articles)
    const dispatch = useAppDispatch()

    useEffect(() => {
        scrollToTop()
        dispatch(fetchByArticles())
    }, [dispatch])

    return (
        <>
            <Helmet>
                <meta property="og:title" content="Статьи | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs" />
                <meta property="og:description" content="Читайте статьи от Phoenix Programs о передовых решениях в автоматизации и различных отраслях. Узнайте о новых технологиях и трендах в нашей сфере." />
                <meta name="twitter:title" content="Статьи | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs" />
                <meta name="twitter:description" content="Читайте статьи от Phoenix Programs о передовых решениях в автоматизации и различных отраслях. Узнайте о новых технологиях и трендах в нашей сфере." />
                <link rel="canonical" href="https://phoenix-programs.com/articles" />
                <title>Статьи | Инновационные решения в автоматизации и различных отраслях | Phoenix Programs</title>
            </Helmet>
            {loading && <Loader />}
            <TopBanner title='Статьи' />
            <Breadcrumbs>
                <NavLink className={({ isActive }) => isActive ? s.active : ''} to={'/'} >Главная</NavLink>
                /
                <NavLink className={({ isActive }) => isActive ? s.active : ''} to={'/articles'} >Статьи</NavLink>
            </Breadcrumbs>
            <section className={s.section}>
                <div className={`${s.wrapper} container`}>
                    {
                        articles?.length !== 0 ?
                            <>
                                {
                                    articles?.map((el) => (
                                        <CardArticle key={el.id} data={el} />
                                    ))
                                }
                            </> :
                            <p className={s.warning}>Материал отсутствует</p>
                    }
                </div>
            </section>

        </>
    )
}

export default Articles