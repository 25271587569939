import React, { FC, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import s from './Feedback.module.scss'
import FormFeedback from '../../../components/FormFeedback/FormFeedback'
import { Link } from 'react-router-dom'

const animation = {
    hidden: { opacity: 0, y: 200 },
    visible: (i: number) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: i * 0.3,
            duration: 0.5
        }
    })
}

const Feedback: FC = () => {
    const controls = useAnimation()
    const { ref, inView } = useInView({ threshold: 0.1 })

    useEffect(() => {
        if (inView) {
            controls.start('visible')
        } else {
            controls.start('hidden')
        }
    }, [controls, inView])

    return (
        <section className={s.section}>
            <span className={`${s.lineGradient} lineGradient top-line`} />
            <motion.div
                className={`${s.wrapper} container`}
                initial="hidden"
                animate={controls}
                variants={animation}
                ref={ref}
            >
                <div className={s.content}>
                    <h2 className={`${s.title} title`}><span />Оставь заявку на<br />бесплатную  консультацию</h2>
                    <p>Мы перезвоним вам<br />
                        в ближайшее время</p>
                </div>
                <div className={s.box_form}>
                    <FormFeedback />
                    <p className={s.privacy_policy}>Нажимая кнопку, вы даёте согласие<br />
                        на <Link to={'/privacy-policy'}>обработку персональных данных</Link></p>
                </div>
            </motion.div>
        </section>
    )
}

export default Feedback
