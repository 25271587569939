import React, { FC, useEffect, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import s from './AboutUs.module.scss'
import { Link } from 'react-router-dom'
import { ReactComponent as Arrow } from './../../../assets/icons/arrow.svg'
import { useAppSelector } from '../../../store/hooks'
import { BACKEND_URL } from '../../../api'

const videoAnimation = {
    hidden: { scale: 0.5 },
    visible: {
        scale: 1,
        transition: { duration: 0.8 }
    }
}

const textAnimation = {
    hidden: { opacity: 0, x: 100 },
    visible: {
        opacity: 1,
        x: 0,
        transition: { duration: 0.8 }
    }
}

const AboutUs: FC = () => {
    const { info } = useAppSelector(state => state.info)
    const videoControls = useAnimation()
    const textControls = useAnimation()
    const { ref, inView } = useInView({ threshold: 0.1 })
    const [hasAnimated, setHasAnimated] = useState(false)

    useEffect(() => {
        if (inView && !hasAnimated) {
            videoControls.start('visible')
            textControls.start('visible')
            setHasAnimated(true)
        }
    }, [videoControls, textControls, inView, hasAnimated])


    return (
        <section className={s.section} ref={ref}>
            <div className={`${s.container} container`}>
                <h2 className='title'><span /> О НАС</h2>
                <div className={s.wrapper}>
                    <motion.div
                        className={s.video}
                        initial="hidden"
                        animate={videoControls}
                        variants={videoAnimation}
                    >
                        {!info || info?.about_us_picture?.type === 'image' ? (
                            <img className={s.picture} src={BACKEND_URL + info?.about_us_picture?.src} alt='AboutUs' />
                        ) : (
                            <video className={s.picture}
                                autoPlay
                                controls
                                muted
                            >
                                <source src={BACKEND_URL + info?.about_us_picture?.src} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </motion.div>
                    <motion.div
                        className={s.text}
                        initial="hidden"
                        animate={textControls}
                        variants={textAnimation}
                    >
                        <article>
                            {info?.about_us_article}
                        </article>
                        <Link className={s.btn} to='/videos'>БОЛЬШЕ ВИДЕО<Arrow /></Link>
                    </motion.div>
                </div>
            </div>
            <span className='lineGradient bottom-line' />
            <div className={s.shape_left} />
        </section>
    )
}

export default AboutUs
