import React, { FC } from 'react'
import s from './Footer.module.scss'
import { ReactComponent as Logo } from './../../assets/icons/logo.svg'
import { ReactComponent as Phone } from './../../assets/icons/phone.svg';
import { ReactComponent as Mail } from './../../assets/icons/mail.svg';
import { ReactComponent as Telegram } from './../../assets/icons/telegram.svg';
import { ReactComponent as Youtube } from './../../assets/icons/youtube.svg';
import { ReactComponent as Whatsapp } from './../../assets/icons/whatsapp.svg';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';

const Footer: FC = () => {
    const { info } = useAppSelector(state => state.info)

    return (
        <footer className={s.footer}>
            <div className={s.logo}><Logo /></div>
            <div className={s.contacts}>
                <div>
                    <a rel="noreferrer noopener" href={`mailto:${info?.contacts?.email}`}><Mail />{info?.contacts?.email}</a>
                    <a rel="noreferrer noopener" href={` ${info?.contacts?.phone}`}><Phone />{info?.contacts?.phone}</a>
                </div>
                <div>
                    <span>{info?.contacts?.inn}</span>
                    <span>{info?.contacts?.address}</span>
                </div>
            </div>
            <div className={`${s.box_copyright} container`}>
                <div>
                    <ul>
                        <li><a target='_blank' rel="noreferrer noopener" href={`https://t.me/${info?.contacts?.telegram}`}><Telegram /></a></li>
                        <li><a target='_blank' rel="noreferrer noopener" href={`https://www.youtube.com/${info?.contacts?.youtube}`}><Youtube /></a></li>
                        <li><a target='_blank' rel="noreferrer noopener" href={`https://wa.me/${info?.contacts?.whatsapp}`}><Whatsapp /></a></li>
                    </ul>
                    <Link className={s.privacypolice} to={'/privacy-policy'}>Политика конфиденциальности</Link>
                </div>
                <p className={s.copyright}>{info?.contacts?.copyright}</p>
            </div>
        </footer>
    )
}

export default Footer