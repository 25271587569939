import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storesApi } from "../../api";
import { IDetailProject, IProject } from "../types";


type IProjects = {
    loading: boolean;
    error: string | null;
    projects: IProject[] | null
    detail: IDetailProject | null
}

const initialState: IProjects = {
    loading: false,
    error: null,
    projects: null,
    detail: null
};

export const fetchByProjects = createAsyncThunk(
    'projects/fetchByProjects',
    async (_, { rejectWithValue }) => {
        const res = await storesApi.getProjects()
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data.data
    })




export const fetchByDetailProject = createAsyncThunk<IDetailProject, number, { rejectValue: string }>(
    'projects/fetchByDetailProject',
    async (id, { rejectWithValue }) => {
        const res = await storesApi.getDetailProject(id)

        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data
    })




const infoSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchByProjects.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        addCase(fetchByProjects.fulfilled, (state, action) => {
            state.projects = action.payload;
            state.loading = false;
        });
        addCase(fetchByProjects.rejected, (state) => {
            state.loading = false;
            state.error = 'Ошибка сервера!';
        });

        // ===================================
        addCase(fetchByDetailProject.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        addCase(fetchByDetailProject.fulfilled, (state, action) => {
            state.detail = action.payload;
            state.loading = false;
        });
        addCase(fetchByDetailProject.rejected, (state) => {
            state.loading = false;
            state.error = 'Ошибка сервера!';
        });
    },
})



export default infoSlice.reducer





