
import React, { useRef, useEffect } from 'react';
import VanillaTilt, { TiltOptions } from 'vanilla-tilt';

interface TiltProps {
    options?: TiltOptions;
    className?: string;
    children?: React.ReactNode;
}

interface TiltHTMLElement extends HTMLDivElement {
    vanillaTilt?: any;
}

const Tilt: React.FC<TiltProps> = ({ options, className, children }) => {
    const tiltRef = useRef<TiltHTMLElement>(null);

    useEffect(() => {
        if (tiltRef.current) {
            VanillaTilt.init(tiltRef.current, options || {});
        }

        return () => {
            if (tiltRef.current?.vanillaTilt) {
                tiltRef.current.vanillaTilt.destroy();
            }
        };
    }, [options]);
    return (
        <div ref={tiltRef} className={className}>
            {children}
        </div>
    );
};

export default Tilt;
