import React, { FC } from 'react'
import Main from './components/Main/Main'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import './App.scss'
import 'animate.css';
import HelmetSchema from './components/HelmetSchema/HelmetSchema'

const App: FC = () => {
  return (
    <>
      <HelmetSchema />
      <Header />
      <Main />
      <Footer />
    </>
  )
}

export default App