import React, { FC } from 'react'
import s from './Description.module.scss'
import { useAppSelector } from '../../../store/hooks'
import { BACKEND_URL } from '../../../api'
import BG from './../../../assets/images/services_bg.svg'

const Description: FC = () => {
    const { services } = useAppSelector(state => state.services)
    const { info } = useAppSelector(state => state.info)

    return (
        <section className={s.section}>
            <div className={`${s.wrapper} container`}>
                <div className={s.box_img}>
                    <div className={s.img}>
                        <img className={s.bg} src={BG} alt="bg" />
                        <img className={s.icon} src={BACKEND_URL + services?.b_icon} alt="icon" />
                    </div>
                </div>
                <div className={s.content}>
                    <article>
                        {services?.text}
                    </article>
                    <a className='button' target='_blank' rel="noreferrer noopener" href={`https://wa.me/${info?.contacts?.whatsapp}`}>Напишите нам</a>
                </div>
            </div>
        </section>
    )
}

export default Description