import React from 'react';
import s from './ApproachWork.module.scss';
import { useAppSelector } from '../../../store/hooks';

interface DataItem {
    number: number;
    title: string;
    description: string;
}

const ApproachWork: React.FC = () => {
    const { aboutUs } = useAppSelector(state => state.info)
    const approachWork = aboutUs?.approach_work || [];
    const midIndex = Math.ceil(approachWork.length / 2);
    const leftColumn = approachWork.slice(0, midIndex);
    const rightColumn = approachWork.slice(midIndex);


    return (
        <section className={s.section}>
            <div className='container'>
                <h2 className='title'><span /> Наши принципы и подход к работе</h2>
                <div className={s.wrapper}>
                    {aboutUs?.approach_work.length !== 0 ? (
                        <>
                            <div className={s.column}>
                                {leftColumn.map(el => (
                                    <div key={el.number} className={s.card}>
                                        <div className={s.number}>{el.number}</div>
                                        <div className={s.box_text}>
                                            <h3 className={s.title}>{el.title}</h3>
                                            <p className={s.description}>{el.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={s.column}>
                                {rightColumn.map(el => (
                                    <div key={el.number} className={s.card}>
                                        <div className={s.number}>{el.number}</div>
                                        <div className={s.box_text}>
                                            <h3 className={s.title}>{el.title}</h3>
                                            <p className={s.description}>{el.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <p className={s.warning}>Данные о подходе к работе отсутствуют</p>
                    )}
                </div>
            </div>
        </section>
    );

};

export default ApproachWork;
