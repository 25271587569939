import React, { FC, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import s from './Advantages.module.scss'
import { ReactComponent as LineAdvantages1 } from './../../../assets/icons/line-Advantages1.svg'
import { ReactComponent as LineAdvantages2 } from './../../../assets/icons/line-Advantages2.svg'
import { ReactComponent as LineAdvantages3 } from './../../../assets/icons/line-Advantages3.svg'
import { ReactComponent as LineAdvantages4 } from './../../../assets/icons/line-Advantages4.svg'
import { ReactComponent as LineAdvantages5 } from './../../../assets/icons/line-Advantages5.svg'
import { ReactComponent as LineAdvantages6 } from './../../../assets/icons/line-Advantages6.svg'
import { ReactComponent as LineAdvantages7 } from './../../../assets/icons/line-Advantages7.svg'

const isMobile = window.innerWidth <= 768;
const animation = {
    hidden: { opacity: 0, x: isMobile ? -200 : -1000 },
    visible: (i: number) => ({
        opacity: 1,
        x: 0,
        transition: {
            delay: isMobile ? i * 0.2 : i * 0.3,
            duration: isMobile ? 0.3 : 0.5,
        }
    }),
    hovered: {
        x: 40,
        transition: { duration: 0.1 },
    },
    initialPosition: {
        x: 0,
        transition: { duration: 0.1 },
    }
}

const Advantages: FC = () => {

    const controls = useAnimation()
    const { ref, inView } = useInView({ threshold: 0.1, triggerOnce: true })

    useEffect(() => {
        if (inView) {
            controls.start('visible')
        }
    }, [controls, inView])

    return (
        <section className={s.section}>
            <LineAdvantages2 className={`${s.lineAdvantages} ${s.lineAdvantages_line2}`} />
            <LineAdvantages6 className={`${s.lineAdvantages} ${s.lineAdvantages_line6}`} />
            <div className={`container`}>
                <h2 className={`${s.title} title`}><span /> ПОЧЕМУ ВЫБИРАЮТ НАС</h2>
                <div className={s.wrapper} ref={ref}>
                    {[
                        { id: 1, title: 'Профессионализм', text: 'Не бояться сложных задач. Сложные задачи позволяют расти, обретая новые навыки', Line: LineAdvantages1 },
                        { id: 2, title: 'Честность', text: 'Делать работу честно и дорожить репутацией', Line: LineAdvantages3 },
                        { id: 3, title: 'Интерес', text: 'Делать проект с удовольствием и полностью отдаваться делу', Line: LineAdvantages4 },
                        { id: 4, title: 'Результат', text: 'Быть внимательным к деталям. Каждая мелочь может привести к эффекту бабочки', Line: LineAdvantages5 },
                        { id: 5, title: 'Индивидуальный подход', text: 'Советовать клиенту только то, что ему действительно будет ценным', Line: LineAdvantages7 }
                    ].map((item, index) => (
                        <motion.div
                            key={item.id}
                            className={s.text}
                            custom={index}
                            initial="hidden"
                            animate={controls}
                            variants={animation}
                            whileHover="hovered"
                            onHoverEnd={() => controls.start('initialPosition')}
                        >
                            <item.Line className={s.line} />
                            <h2>{item.title}</h2>
                            <p>{item.text}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Advantages
