import { configureStore } from "@reduxjs/toolkit";
import infoSlice from "./slices/infoSlice";
import servicesSlice from "./slices/servicesSlice";
import projectSlice from "./slices/projectSlice";
import articlesSlice from "./slices/articlesSlice";
import videosSlice from "./slices/videosSlice";



export const store = configureStore({
    reducer: {
        info: infoSlice,
        services: servicesSlice,
        projects: projectSlice,
        articles: articlesSlice,
        videos: videosSlice
    },
    devTools: false

})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch