import React, { FC, useEffect, useState } from 'react'
import TopBanner from '../../components/TopBanner/TopBanner'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { NavLink, useLocation } from 'react-router-dom'
import s from './Project.module.scss'
import CardProject from '../../components/CardProject/CardProject'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { fetchByProjects } from '../../store/slices/projectSlice'
import { scrollToTop } from '../../utils'
import { LayoutGroup } from 'framer-motion'
import Loader from '../../components/Loader/Loader'
import { Helmet } from 'react-helmet-async'

const Projects: FC = () => {
    const { projects, loading } = useAppSelector(state => state.projects)


    const dispatch = useAppDispatch()
    useEffect(() => {
        scrollToTop()
        dispatch(fetchByProjects())

    }, [dispatch])

    return (
        <>
            <Helmet>
                <meta property="og:title" content="Проекты | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs" />
                <meta property="og:description" content="Ознакомьтесь с нашими проектами в сфере автоматизации и разработки ПО. Phoenix Programs реализует инновационные решения для различных отраслей, обеспечивая высокое качество и эффективность." />
                <meta name="twitter:title" content="Проекты | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs" />
                <meta name="twitter:description" content="Ознакомьтесь с нашими проектами в сфере автоматизации и разработки ПО. Phoenix Programs реализует инновационные решения для различных отраслей, обеспечивая высокое качество и эффективность." />
                <link rel="canonical" href="https://phoenix-programs.com/projects" />
                <title>Проекты | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs</title>
            </Helmet>
            {loading && <Loader />}
            <LayoutGroup id={"project"}>
                <TopBanner title='Проекты' />
                <Breadcrumbs>
                    <NavLink to={'/'}>Главная</NavLink>
                    /
                    <NavLink to={`/projects`}>Проекты</NavLink>
                </Breadcrumbs>
                <section>
                    <div className={`${s.wrapper} container`}>
                        {projects?.length !== 0 ? <>
                            {projects?.map((el) => (
                                <CardProject key={el.id} data={el} />
                            ))}
                        </>
                            :
                            <p className={s.warning}>Материал отсутствует</p>
                        }
                    </div>
                </section>
            </LayoutGroup>
        </>
    )
}

export default Projects
