import React, { FC } from 'react'
import { ReactComponent as Chevron } from './../../assets/icons/chevron.svg'
import s from './Dropdown.module.scss'

interface DropdownProps {
    title: string;
    children: React.ReactNode;
}


const Dropdown: FC<DropdownProps> = ({ title, children }) => {
    return (
        <div className={s.dropdown}>
            <button className={s.btn}>
                {title}
                <Chevron />
            </button>
            <div className={s.dropdown_menu}>
                {children}
            </div>
        </div>
    )
}

export default Dropdown