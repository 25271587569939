import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storesApi } from "../../api";
import { ICertainServices, IDetail, IService } from "../types";


const initialState: ICertainServices = {
    loading: false,
    error: null,
    services: null,
    detail: null
};

export const fetchByCertainServices = createAsyncThunk<IService, number, { rejectValue: string }>(
    'services/fetchByCertainServices',
    async (id, { rejectWithValue }) => {
        const res = await storesApi.getCertainServices(id)
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data
    })




export const fetchByDetailService = createAsyncThunk<IDetail, number, { rejectValue: string }>(
    'services/fetchByDetailService',
    async (id, { rejectWithValue }) => {
        const res = await storesApi.getDetailService(id)
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data
    })




const infoSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchByCertainServices.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        addCase(fetchByCertainServices.fulfilled, (state, action) => {
            state.services = action.payload;
            state.loading = false;
        });
        addCase(fetchByCertainServices.rejected, (state) => {
            state.loading = false;
            state.error = 'Ошибка сервера!';
        });

        // ===================================

        addCase(fetchByDetailService.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        addCase(fetchByDetailService.fulfilled, (state, action) => {
            state.detail = action.payload;
            state.loading = false;
        });
        addCase(fetchByDetailService.rejected, (state) => {
            state.loading = false;
            state.error = 'Ошибка сервера!';
        });

    },
})



export default infoSlice.reducer





