import React, { useState } from 'react';
import s from './BurgerMenu.module.scss';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from './../../assets/icons/logo.svg';
import { useAppSelector } from '../../store/hooks';
import { ReactComponent as Phone } from './../../assets/icons/phone.svg';
import { ReactComponent as Mail } from './../../assets/icons/mail.svg';
import { ReactComponent as Telegram } from './../../assets/icons/telegram.svg';
import { ReactComponent as Youtube } from './../../assets/icons/youtube.svg';
import { ReactComponent as Whatsapp } from './../../assets/icons/whatsapp.svg';
import { ReactComponent as Chevron } from './../../assets/icons/chevron.svg'
import { motion } from 'framer-motion';

const BurgerMenu = () => {
    const [isClosed, setIsClosed] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const { info } = useAppSelector(state => state.info);

    const handleClick = () => {
        setIsClosed(!isClosed);
    };

    const toggleServices = () => {
        setIsServicesOpen(!isServicesOpen);
    };

    return (
        <div className={`${s.container} container`}>
            <div className={s.header}>
                <Link onClick={() => setIsClosed(false)} className={s.logo} to='/'>
                    <Logo />
                </Link>
                <button onClick={handleClick} className={s.button}>
                    <svg fill='#000' className={`${s.burger6} ${s.ham} ${s.hamRotate} ${s.ham1} ${isClosed ? s.isClosed : ''}`} viewBox="0 0 100 100">
                        <path
                            className={`${s.line} ${s.top}`}
                            d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
                        />
                        <path className={`${s.line} ${s.middle}`} d="m 30,50 h 40" />
                        <path
                            className={`${s.line} ${s.bottom}`}
                            d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
                        />
                    </svg>
                </button>
            </div>
            <div className={`${s.wrapper} ${isClosed && s.active}`}>
                <nav>
                    <ul>
                        <li className={s.servicesButton}>
                            <button onClick={toggleServices} >
                                Услуги
                                <Chevron />
                            </button>
                            <motion.ul
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: isServicesOpen ? 'auto' : 0, opacity: isServicesOpen ? 1 : 0 }}
                                transition={{ duration: 0.3 }}
                                className={s.servicesDropdown}
                            >
                                {info?.list_services?.map((el) => (
                                    <li key={el.id}><NavLink onClick={handleClick} className={({ isActive }) => isActive ? s.active : ''} to={`/services/${el.id}/${el.name}`}>{el.name}</NavLink></li>
                                ))}
                            </motion.ul>
                        </li>
                        <li><NavLink onClick={handleClick} className={({ isActive }) => isActive ? s.active : ''} to={'/projects'}>Проекты</NavLink></li>
                        <li><NavLink onClick={handleClick} className={({ isActive }) => isActive ? s.active : ''} to={'/about-us'}>О нас</NavLink></li>
                        <li><NavLink onClick={handleClick} className={({ isActive }) => isActive ? s.active : ''} to={'/videos'}>Видео</NavLink></li>
                        <li><NavLink onClick={handleClick} className={({ isActive }) => isActive ? s.active : ''} to={'/articles'}>Статьи</NavLink></li>
                    </ul>
                </nav>
                <ul className={s.contacts}>
                    <div>
                        <li><a rel="noreferrer noopener" href={`tel:${info?.contacts?.phone}`}><Phone />{info?.contacts?.phone}</a></li>
                        <li><a rel="noreferrer noopener" href={`mailto:${info?.contacts?.email}`}><Mail />{info?.contacts?.email}</a></li>
                    </div>
                    <div>
                        <li><a target='_blank' rel="noreferrer noopener" href={`https://t.me/${info?.contacts?.telegram}`}><Telegram /></a></li>
                        <li><a target='_blank' rel="noreferrer noopener" href={`https://www.youtube.com/${info?.contacts?.youtube}`}><Youtube /></a></li>
                        <li><a target='_blank' rel="noreferrer noopener" href={`https://wa.me/${info?.contacts?.whatsapp}`}><Whatsapp /></a></li>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default BurgerMenu;
