import React, { FC } from 'react'
import s from './MoreAboutUs.module.scss'
import { useAppSelector } from '../../../store/hooks'
const MoreAboutUs: FC = () => {
    const { aboutUs } = useAppSelector(state => state.info)

    return (
        <section className={s.section}>
            <div className={`${s.shape_right}`} />
            <div className='container'>
                <h2 className='title'> <span />Подробнее о нас</h2>
                <article>
                    {aboutUs?.more_description}
                </article>
            </div>
        </section>
    )
}

export default MoreAboutUs