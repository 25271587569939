import React, { FC } from 'react'
import s from './ListServices.module.scss'
import { Link, useParams } from 'react-router-dom'
import { ReactComponent as ArrowList } from './../../../assets/icons/arrow-list.svg'
import { useAppSelector } from '../../../store/hooks'
import { BACKEND_URL } from '../../../api'
import Tilt from '../../../components/Tilt/Tilt'

const ListServices: FC = () => {
    const isMobile = window.innerWidth <= 992;
    const { id, name } = useParams()
    const { services } = useAppSelector(state => state.services)



    const options = {
        scale: 1,
        speed: 1000,
        max: 5,
    };

    return (
        <section>
            <div className={`${s.container}`}>
                <div className={s.wrapper}>
                    <h2 className={`${s.title} title`}><span /> Какую разработку мы предоставляем:</h2>
                    <div className={s.content}>
                        <ul className={s.list}>
                            {services?.spisok_uslugs?.map((el) => (
                                <li key={el.id}>
                                    <Link to={`/detail-service/${el.id}/${el.title}`}>{el.title}<ArrowList /></Link>
                                </li>
                            ))}
                        </ul>
                        <div className={s.img}>
                            {isMobile ? (
                                <div className={s.box_img}>
                                    <img src={BACKEND_URL + services?.img} alt="img-services" />
                                </div>
                            ) : (
                                <Tilt className={s.box_img} options={options}>
                                    <img src={BACKEND_URL + services?.img} alt="img-services" />
                                </Tilt>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ListServices