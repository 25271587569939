import React, { FC, useEffect, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import s from './Services.module.scss'
import CardServices from '../../../components/CardServices/CardServices'
import { useAppSelector } from '../../../store/hooks'
import { animationLifting } from '../../../utils'



const Services: FC = () => {
    const { info } = useAppSelector(state => state.info)
    const controls = useAnimation()
    const { ref, inView } = useInView({ threshold: 0.1 })
    const [hasAnimated, setHasAnimated] = useState(false)

    useEffect(() => {
        if (inView && !hasAnimated) {
            controls.start('visible')
            setHasAnimated(true)
        }
    }, [controls, inView, hasAnimated])

    return (
        <section className={s.section}>
            <div className={`${s.container} container`}>
                <h2 className={`${s.title} title`}><span></span>МЫ ПРЕДЛАГАЕМ</h2>
                <motion.div
                    className={`${s.wrapper}`}
                    initial="hidden"
                    animate={controls}
                    variants={animationLifting}
                    ref={ref}
                >
                    {
                        info?.list_services?.map((el) => (
                            <CardServices
                                key={el.id}
                                data={el}
                            />
                        ))
                    }
                </motion.div>
            </div>
            <div className={s.shape_right} />
            <span className='lineGradient bottom-line ' />
        </section>
    )
}

export default Services
