import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import s from './CardServices.module.scss'
import { BACKEND_URL } from '../../api';

interface list_services_spisok {
    id: number;
    title: string;
}

interface dataProps {
    data: {
        id: number
        b_icon: string
        list_services_spisok: list_services_spisok[]
        name: string
    }
}

const CardServices: FC<dataProps> = ({ data }) => {
    return (
        <Link to={`/services/${data.id}/${data.name}`} className={s.card}>
            <div className={s.service}>
                <img src={BACKEND_URL + data.b_icon} alt="icon" />
                <h2>{data?.name}</h2>
            </div>
            <div className={s.service_detail}>
                <h3>Документация:</h3>
                <ul>
                    {data.list_services_spisok?.slice(0, 5).map((item, index) => (
                        <li key={index}>{item.title}</li>
                    ))}
                </ul>
            </div>
        </Link >
    )
}

export default CardServices
