import { Helmet } from 'react-helmet-async';

const HelmetSchema = () => (
    <Helmet>
        <script type="application/ld+json">
            {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "position": 1,
                        "name": "Главная ",
                        "item": "https://phoenix-programs.com/"
                    },
                    {
                        "@type": "ListItem",
                        "position": 2,
                        "name": "О нас",
                        "item": "https://phoenix-programs.com/about-us"
                    },
                    {
                        "@type": "ListItem",
                        "position": 3,
                        "name": "Проекты",
                        "item": "https://phoenix-programs.com/projects"
                    },
                    {
                        "@type": "ListItem",
                        "position": 4,
                        "name": "Видео",
                        "item": "https://phoenix-programs.com/videos"
                    },
                    {
                        "@type": "ListItem",
                        "position": 5,
                        "name": "Статьи",
                        "item": "https://phoenix-programs.com/articles"
                    },
                ]
            })}
        </script>
    </Helmet>
);

export default HelmetSchema;
