import React, { FC, ReactElement } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import s from './Breadcrumbs.module.scss'

interface BreadcrumbsProps {
    children: React.ReactNode
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ children }) => {

    return (
        <section className={`${s.wrapper} container`}>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child) && child.type === NavLink) {
                    const navLinkElement = child as ReactElement<NavLinkProps>;
                    return React.cloneElement(navLinkElement, {
                        className: ({ isActive }: { isActive: boolean }) =>
                            isActive ? `${s.link} ${s.active}` : s.link
                    });
                }
                return child;
            })}
        </section>
    )
}

export default Breadcrumbs
