import React, { FC, useEffect } from 'react'
import TopBanner from '../../components/TopBanner/TopBanner'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { NavLink } from 'react-router-dom'
import s from './AboutUs.module.scss'
import { Helmet } from 'react-helmet-async'

import MoreAboutUs from './MoreAboutUs/MoreAboutUs'
import OurTeam from './OurTeam/OurTeam'
import ApproachWork from './ApproachWork/ApproachWork'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { fetchByAboutUs } from '../../store/slices/infoSlice'
import Loader from '../../components/Loader/Loader'
const AboutUs: FC = () => {
    const { loading } = useAppSelector(state => state.info)

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchByAboutUs())
    }, [dispatch])

    return (
        <>
            <Helmet>
                <meta property="og:title" content="Главная | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs" />
                <meta property="og:description" content="Phoenix Programs предоставляет передовые решения в области автоматизации, веб-разработки и инженерии. Узнайте о наших услугах и проектах." />
                <meta name="twitter:title" content="Главная | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs" />
                <meta name="twitter:description" content="Phoenix Programs предоставляет передовые решения в области автоматизации, веб-разработки и инженерии. Узнайте о наших услугах и проектах." />
                <link rel="canonical" href="https://phoenix-programs.com/about-us" />
                <title>О нас | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs</title>
            </Helmet>
            <TopBanner title='О нас' />
            {loading && <Loader />}
            <Breadcrumbs>
                <NavLink className={({ isActive }) => isActive ? s.active : ''} to={'/'} >Главная</NavLink>
                /
                <NavLink className={({ isActive }) => isActive ? s.active : ''} to={'/about-us'} >О нас</NavLink>
            </Breadcrumbs>
            <>
                <OurTeam />
                <MoreAboutUs />
                <ApproachWork />
            </>
        </>
    )
}

export default AboutUs