import axios from "axios";
export const BACKEND_URL = 'https://back.phoenix-programs.com'
// export const BACKEND_URL = 'http://localhost:1338'


const instanse = axios.create({
    baseURL: `${BACKEND_URL}/api`,
    headers: {
        'Content-Type': 'application/json'
    }
})

export const storesApi = {
    getMainAndInfoCompany() {
        return instanse.get('/main?populate=deep')
    },
    getCertainServices(id: number) {
        return instanse.get(`/all-services/${id}?populate=deep`)
    },
    getDetailService(id: number) {
        return instanse.get(`/services/${id}?populate=deep`)
    },
    getProjects() {
        return instanse.get('/projects?populate=deep')
    },
    getDetailProject(id: number) {
        return instanse.get(`/projects/${id}?populate=deep`)
    },
    getArticles() {
        return instanse.get('/articles?populate=deep')
    },
    getDetailArticle(id: number) {
        return instanse.get(`/articles/${id}?populate=deep`)
    },
    getVideos() {
        return instanse.get('/videos')
    },
    getDetailVideo(id: number) {
        return instanse.get(`/videos/${id}`)
    },
    getAboutUs() {
        return instanse.get('/about-us?populate=deep')
    }
}


