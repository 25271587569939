import React, { FC } from 'react'
import s from './CardArticle.module.scss'
import { IArticles } from '../../store/types'
import { truncateText } from '../../utils'
import { motion } from 'framer-motion'
import { ReactComponent as ArrowRight } from './../../assets/icons/arrow_right.svg'
import { BACKEND_URL } from '../../api'
import { Link } from 'react-router-dom'
interface dataProps {
    data: null | IArticles
}
const CardArticle: FC<dataProps> = ({ data }) => {
    return (

        <Link to={`/detail-article/${data?.id}/${data?.title}`} className={s.card}>
            <div className={s.box_img}>
                <img src={BACKEND_URL + data?.image} alt='article image' />
            </div>
            <div className={s.wrapper}>
                <div className={s.date}>
                    <p>Дата выпуска:</p>
                    <span>{data?.publishedAt}</span>
                </div>
                <div className={s.box_content}>
                    <h2 className='title'><span />{data?.title}</h2>
                    <p>{truncateText(data?.preview_text, 45)}</p>
                </div>
                <div className={s.btn}>
                    <button className='button_card'>Подробнее<ArrowRight /></button>
                </div>
            </div>
        </Link>
    )
}

export default CardArticle