import React from 'react'
import Hero from './Hero/Hero'
import Services from './Services/Services'
import AboutUs from './AboutUs/AboutUs'
import OurServices from './OurServices/OurServices'
import Advantages from './Advantages/Advantages'
import Feedback from './Feedback/Feedback'
import { useAppSelector } from '../../store/hooks'
import Loader from '../../components/Loader/Loader'
import { Helmet } from 'react-helmet-async'


const Home = () => {
    const { loading } = useAppSelector(state => state.info)

    return (
        <>
            <Helmet>
                <meta property="og:title" content="Главная | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs" />
                <meta property="og:description" content="Phoenix Programs предоставляет передовые решения в области автоматизации, веб-разработки и инженерии. Узнайте о наших услугах и проектах." />
                <meta name="twitter:title" content="Главная | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs" />
                <meta name="twitter:description" content="Phoenix Programs предоставляет передовые решения в области автоматизации, веб-разработки и инженерии. Узнайте о наших услугах и проектах." />
                <link rel="canonical" href="https://phoenix-programs.com/" />
                <title>Главная | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs</title>
            </Helmet>
            {loading && <Loader />}
            <Hero />
            <Services />
            <AboutUs />
            <OurServices />
            <Advantages />
            <Feedback />
        </>
    )
}

export default Home