import React, { FC } from 'react'
import s from './OurTeam.module.scss'
import img from '../../../assets/images/23423.jpg'
import { useAppSelector } from '../../../store/hooks'
import { BACKEND_URL } from '../../../api'

const OurTeam: FC = () => {
    const { aboutUs } = useAppSelector(state => state.info)

    return (
        <section className={`${s.section} container`}>
            <div className={s.box_img}>
                {!aboutUs || aboutUs?.images?.type === 'image' ? (
                    <img src={BACKEND_URL + aboutUs?.images?.src} alt='AboutUs' />
                ) : (
                    <video
                        autoPlay
                        controls
                        muted
                    >
                        <source src={BACKEND_URL + aboutUs?.images?.src} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </div>
            <p className={s.text}>{aboutUs?.description}</p>
        </section>
    )
}

export default OurTeam