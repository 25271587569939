import React, { FC, useState } from 'react';
import s from './FormFeedback.module.scss';
import axios from 'axios';
import { ReactComponent as Success } from './../../assets/icons/success.svg'

const FormFeedback: FC = () => {
    const [isSuccess, setIsSuccess] = useState(false);

    const sendTelegramMessage = async (message: string) => {
        const telegramBotToken = '7445980531:AAG9yYzTEsw49iG6iBtVx7m2NT3Eb7EZBjk';
        const telegramChatId = '1695493419';
        const url = `https://api.telegram.org/bot${telegramBotToken}/sendMessage?chat_id=${telegramChatId}&text=${message}`;

        try {
            await axios.get(url);
            setIsSuccess(true);
            setTimeout(() => setIsSuccess(false), 3000);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const name = form.elements[0] as HTMLInputElement;
        const phone = form.elements[1] as HTMLInputElement;

        const message = `Имя: ${name.value}%0AТелефон: ${phone.value}`;
        await sendTelegramMessage(message);
        name.value = '';
        phone.value = '';
    };

    return (
        <>
            <div className={`${s.global_success} ${isSuccess ? s.active : ''}`}><i><Success /></i> Сообщение отправлено</div>
            <form className={s.form} onSubmit={handleSubmit}>
                <input type="text" placeholder="Ваше имя" className={s.input} required />
                <input maxLength={20}
                    onInput={(e) => {
                        const input = e.target as HTMLInputElement;
                        input.value = input.value.replace(/[^\d+ ()]/g, '');
                    }}
                    type="tel" placeholder="+7 (___) ___-__-__" className={s.input} required />
                <button type="submit" className={s.button}>Отправить заявку</button>
            </form>

        </>
    );
};

export default FormFeedback;
