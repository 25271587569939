import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useEffect, useState } from 'react';
import s from './ModalDetailVideo.module.scss';
import parse from 'html-react-parser';
import { ReactComponent as YoutubeIcon } from './../../../assets/icons/youtube-svgrepo-com.svg'
import { IVideos } from '../../../store/types';

interface DataProps {
    setSelectedId: (id: string | null) => void;
    selectedId: string | null;
    data: IVideos | null;
}


const addAutoplayToIframe = (htmlString: string) => {
    return parse(htmlString, {
        replace: (domNode: any) => {
            if (domNode.name === 'iframe' && domNode.attribs?.src) {
                const src = new URL(domNode.attribs.src);
                if (!src.searchParams.has('autoplay')) {
                    src.searchParams.set('autoplay', '1');
                }
                const title = domNode.attribs.title || 'Embedded Video';
                return (
                    <iframe {...domNode.attribs} src={src.toString()} title={title}>
                        {domNode.children.map((child: any, index: number) => (
                            <React.Fragment key={index}>{parse(child)}</React.Fragment>
                        ))}
                    </iframe>
                );
            }
        },
    });
};

const ModalDetailVideo: FC<DataProps> = ({ setSelectedId, selectedId, data }) => {
    const [showIframe, setShowIframe] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowIframe(true);
        }, 500);
        return () => clearTimeout(timer);
    }, [selectedId]);

    useEffect(() => {
        if (selectedId) {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = () => {
                setSelectedId(null);
            };
        }
        return () => {
            window.onpopstate = () => { };
        };
    }, [selectedId, setSelectedId]);

    return (
        <AnimatePresence>
            {selectedId && (
                <motion.div
                    className={s.overlay}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setSelectedId(null)}
                >
                    <motion.div
                        className={s.modal}
                        layoutId={selectedId}
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -50, opacity: 0 }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button className={s.close} onClick={() => setSelectedId(null)}>Х</button>
                        <div className={s.content}>
                            <div className={s.box_img}>
                                {showIframe ? (
                                    addAutoplayToIframe(data?.link ?? "")
                                ) : (
                                    <div className={s.skeleton}>
                                        <YoutubeIcon />
                                    </div>
                                )}
                            </div>
                            <div className={s.box_content}>
                                <h2 className={`${s.title} title`}><span />{data?.title}</h2>
                                <div className={s.date}>
                                    <p>Дата выпуска:</p>
                                    <span>{data?.publishedAt}</span>
                                </div>
                                <div className={s.description}>
                                    <h2 className={s.title_section}>Описание</h2>
                                    <article>{data?.description}</article>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ModalDetailVideo;