import React, { FC, useEffect } from 'react'
import s from './DetailArticle.module.scss'
import parse from 'html-react-parser';
import { BACKEND_URL } from '../../api';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { NavLink, useParams } from 'react-router-dom';
import { scrollToTop } from '../../utils';
import { fetchByDetailArticle } from '../../store/slices/articlesSlice';
import { Helmet } from 'react-helmet-async';
import Loader from '../../components/Loader/Loader';
import TopBanner from '../../components/TopBanner/TopBanner';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';


const DetailArticle: FC = () => {
    const { id, name } = useParams()
    const { detail, loading } = useAppSelector(state => state.articles)
    const dispatch = useAppDispatch()
    console.log(detail);

    useEffect(() => {
        scrollToTop()
        dispatch(fetchByDetailArticle(Number(id)))
    }, [])

    return (
        <>
            <Helmet>
                <meta property="og:title" content={`${name} | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs`} />
                <meta property="og:description" content={`${name} Phoenix Programs предоставляет передовые решения в различных отрослях`} />
                <meta name="twitter:title" content={`${name} | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs`} />
                <meta name="twitter:description" content={`${name} | Phoenix Programs предоставляет передовые решения в различных отрослях`} />
                <link rel="canonical" href={`https://phoenix-programs.com/detail-article/${id}/${name}`} />
                <title>
                    {`${name} | Инновационные решения в автоматизации в различных отрослях | Phoenix Programs`}
                </title>
            </Helmet>
            {loading && <Loader />}
            <TopBanner title={name} />
            <Breadcrumbs>
                <NavLink to={'/'}>Главная</NavLink>
                /
                <NavLink to={`/articles`}>Статьи</NavLink>
                /
                <span>{name}</span>

            </Breadcrumbs>
            <section className='container'>
                <div className={s.content}>
                    <div className={s.date}>
                        <p>Дата выпуска:</p>
                        <span>{detail?.publishedAt}</span>
                    </div>
                    <h1 className={`${s.title} title`}> <span />{detail?.title}</h1>
                    <div className={s.box_img}>
                        <img src={BACKEND_URL + detail?.image} alt='Article Image' />
                    </div>
                    <div className={s.text}>{parse(detail?.description ?? "")}</div>
                </div>
            </section>
        </>
    )
}

export default DetailArticle