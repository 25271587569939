import React, { useEffect } from 'react'
import s from './DetailProject.module.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { NavLink, useParams } from 'react-router-dom'
import { fetchByDetailProject } from '../../store/slices/projectSlice'
import TopBanner from '../../components/TopBanner/TopBanner'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { Helmet } from 'react-helmet-async'
import Loader from '../../components/Loader/Loader'
import SwiperProject from '../../components/SwiperProject/SwiperProject'
import { ReactComponent as Checkmark } from './../../assets/icons/checkmark.svg'
import { scrollToTop } from '../../utils'

const DetailProject = () => {
    const { id, name } = useParams()
    const { detail, loading } = useAppSelector(state => state.projects)
    const dispatch = useAppDispatch()

    useEffect(() => {
        scrollToTop()
        dispatch(fetchByDetailProject(Number(id)));
    }, [dispatch, id]);

    return (
        <>
            <Helmet>
                <meta property="og:title" content={`${name} | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs`} />
                <meta property="og:description" content={`${name} Phoenix Programs предоставляет передовые решения в различных отрослях`} />
                <meta name="twitter:title" content={`${name} | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs`} />
                <meta name="twitter:description" content={`${name} | Phoenix Programs предоставляет передовые решения в различных отрослях`} />
                <link rel="canonical" href={`https://phoenix-programs.com/detail-projects/${id}/${name}`} />
                <title>
                    {`${name} | Инновационные решения в автоматизации в различных отрослях | Phoenix Programs`}
                </title>
            </Helmet>
            {loading && <Loader />}
            <TopBanner title={name} />
            <Breadcrumbs>
                <NavLink to={'/'}>Главная</NavLink>
                /
                <NavLink to={'/projects'}>Проекты</NavLink>
                /
                <span>{name}</span>
            </Breadcrumbs>

            <div className={`${s.content} container`}>
                <div className={s.gallery}>
                    {detail?.preview_img && (
                        <SwiperProject image={detail.preview_img} data={detail?.imagesData || []} />
                    )}
                    <div className={s.box_content}>
                        <h2 className='title'><span />{detail?.title}</h2>
                        <h3 className={s.title}>Произведенные работы:</h3>
                        <ul >
                            {
                                detail?.workPerformed.map((el, index) => (
                                    <li key={index} ><Checkmark />{el}</li>
                                ))
                            }
                        </ul>
                        <h3 className={s.title}>Использованное оборудование:</h3>
                        <p className={s.equipment_used}>{detail?.equipment_used}</p>
                        <p className={s.due_date}>Срок выполнения: <span>{detail?.due_date}</span></p>
                    </div>
                </div>
                <h3 className={s.title}>Описание / техническое задание :</h3>
                <p className={s.description}>{detail?.description}</p>
            </div>
        </>
    )
}

export default DetailProject