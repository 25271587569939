import React, { FC, useEffect } from 'react'
import s from './DetailService.module.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { scrollToTop } from '../../utils'
import { fetchByDetailService } from '../../store/slices/servicesSlice'
import { Link, NavLink, useParams } from 'react-router-dom'
import TopBanner from '../../components/TopBanner/TopBanner'
import { BACKEND_URL } from '../../api'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Loader from '../../components/Loader/Loader'
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet-async'

const DetailService: FC = () => {
    const { id, name } = useParams()
    const dispatch = useAppDispatch()
    const { detail, loading } = useAppSelector(state => state.services)

    useEffect(() => {
        if (id) {
            scrollToTop()
            dispatch(fetchByDetailService(Number(id)))
        }
    }, [dispatch, id])

    return (
        <>
            <Helmet>
                <meta property="og:title" content={`${name} | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs`} />
                <meta property="og:description" content={`${name} Phoenix Programs предоставляет передовые решения в различных отрослях`} />
                <meta name="twitter:title" content={`${name} | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs`} />
                <meta name="twitter:description" content={`${name} | Phoenix Programs предоставляет передовые решения в различных отрослях`} />
                <link rel="canonical" href={`https://phoenix-programs.com/detail-service/${id}/${name}`} />
                <title>
                    {`${name} | Инновационные решения в автоматизации в различных отрослях | Phoenix Programs`}
                </title>
            </Helmet>
            {loading && <Loader />}
            <TopBanner title={name} />
            <Breadcrumbs>
                <NavLink to={'/'}>Главная</NavLink>
                /
                <NavLink to={`/services/${id}/${name}`}>{name}</NavLink>
                /
                <span>{name}</span>

            </Breadcrumbs>
            <section>
                <div className={`${s.wrapper} container`}>
                    <div className={s.content}>
                        {
                            detail?.imageUrl &&
                            <div className={s.img}>
                                <img src={BACKEND_URL + detail?.imageUrl} alt={name} />
                            </div>
                        }

                        <div className={s.text}>{parse(detail?.description ?? "")}</div>
                    </div>
                    <div className={s.wrapper_projects}>
                        <h2 className={`${s.title} title`}><span />{detail?.project_title}</h2>
                        {
                            detail?.projectsData.map((el) => (
                                <Link to={'/projects'} state={{ id: el.id }} key={el.id} className={s.card}>
                                    <img src={BACKEND_URL + el.preview_img} alt={el.title} />
                                    <h2>{el.title}</h2>
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default DetailService