import React, { FC, useEffect } from 'react'
import ListServices from './ListServices/ListServices'
import TopBanner from '../../components/TopBanner/TopBanner'
import { scrollToTop } from '../../utils'
import { fetchByCertainServices } from '../../store/slices/servicesSlice'
import { NavLink, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import Description from './Description/Description'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Loader from '../../components/Loader/Loader'
import { Helmet } from 'react-helmet-async'

const Services: FC = () => {
    const { id, name } = useParams()
    const dispatch = useAppDispatch()
    const { loading } = useAppSelector(state => state.services)
    useEffect(() => {
        if (id) {
            scrollToTop()
            dispatch(fetchByCertainServices(Number(id)))
        }
    }, [dispatch, id])

    return (
        <>
            <Helmet>
                <meta property="og:title" content={`${name} | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs`} />
                <meta property="og:description" content={`${name} Phoenix Programs предоставляет передовые решения В различных отрослях`} />
                <meta name="twitter:title" content={`${name} | Инновационные решения в автоматизации и разработке ПО | Phoenix Programs`} />
                <meta name="twitter:description" content={`${name} Phoenix Programs предоставляет передовые решения В различных отрослях`} />
                <link rel="canonical" href={`https://phoenix-programs.com/services/${id}/${name}`} />
                <title>
                    {`${name}| Инновационные решения в автоматизации в различных отрослях | Phoenix Programs`}
                </title>
            </Helmet>
            {loading && <Loader />}
            <TopBanner title={name} />
            <Breadcrumbs>
                <NavLink to={'/'}>Главная</NavLink>
                /
                <NavLink to={`/services/${id}/${name}`}>{name}</NavLink>
            </Breadcrumbs>
            <ListServices />
            <Description />
        </>
    )
}

export default Services